<template>
  <div class="mt-4">
    <div class="row justify-between mb-4">
      <div class="col">{{ user.name }}</div>
      <div class="col">{{ formatDate(user.createdAt) }}</div>
      <div class="col">{{ user.phone }}</div>
      <div class="col">{{ user.username }}</div>
      <div class="col">{{ user.email }}</div>
    </div>
    <div class="text-right mb-5">
      <b-button @click="editProfile = true" variant="primary">
        Edit Profile
      </b-button>
    </div>
    <div class="bg-primary curved-edge">
      <b-row class="align-items-end justify-content-between text-white py-4 px-3">
        <b-col cols="4">
          <p class="text-uppercase text-14">Wallet</p>
          <p class="text-uppercase text-36">₦ {{ wallet.balance }}</p>
          <p class="text-uppercase text-14 mb-0">User Balance</p>
        </b-col>
        <b-col cols="4">
          <p class="text-uppercase text-14">Lien</p>
          <p class="text-uppercase text-36">₦ {{ wallet.lien }}</p>
          <b-button variant="outline-light" class="mr-3" @click="editLien = true">
            Edit User Lien
          </b-button>
        </b-col>
        <b-col cols="4" class="text-right">
          <b-button variant="outline-light" class="mr-3" @click="editWallet = true">
            Edit Wallet
          </b-button>
          <!-- <b-button variant="outline-light">
            View Transactions
          </b-button> -->
        </b-col>
      </b-row>
    </div>
    <!-- edit profile -->
    <b-modal v-model="editProfile" content-class="af-modal" size="lg" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold text-18 mb-4">Edit Profile</p>
      <form @submit.prevent="updateUser">
        <b-row>
          <b-col cols="6">
            <p class="text-14">FULL NAME</p>
            <b-form-input v-model="name" class="af-input-field mb-4"></b-form-input>
          </b-col>
          <b-col cols="6">
            <p class="text-14">EMAIL</p>
            <b-form-input v-model="email" class="af-input-field mb-4"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="7">
            <p class="text-14">MANAGER EMAIL</p>
            <b-form-input v-model="managerEmail" class="af-input-field mb-4"></b-form-input>
          </b-col>
          <b-col cols="5">
            <p class="text-14">PHONE NUMBER</p>
            <b-form-input v-model="phone" class="af-input-field mb-4"></b-form-input>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button :disabled="processing" type="submit" variant="primary" class="px-4">
            <b-spinner small v-if="processing" label="Spinning"></b-spinner>
            <span v-else>Update</span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- edit wallet modal -->
    <b-modal v-model="editWallet" content-class="af-modal" size="lg" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold text-18 mb-4">Current Balance</p>
      <div class="text-right">
        <p class="text-36 font-weight-bold mb-1">₦ {{ wallet.balance }}</p>
        <p class="text-uppercase text-14 mb-4">Main Balance</p>
      </div>
      <b-row class="align-items-end">
        <b-col cols="3">
          <p class="text-14 text-uppercase">Condition</p>
          <b-form-select v-model="selected" :options="options" class="w-100"></b-form-select>
        </b-col>
        <b-col cols="6">
          <p class="text-14 text-uppercase">Amount</p>
          <b-form-input v-model="amount" class="af-input-field"></b-form-input>
        </b-col>
        <div>
          <b-button @click="updateWallet" :disabled="processing" variant="outline-primary">
            <span v-if="!processing">Update</span>
            <span v-else>Updating...</span>
          </b-button>
        </div>
      </b-row>
      <div class="text-right mt-5">
        <b-button variant="primary" class="px-4" @click="editWallet = false">
          Done
        </b-button>
      </div>
    </b-modal>
     <!-- edit lien modal -->
     <b-modal v-model="editLien" content-class="af-modal" size="lg" hide-header hide-footer centered>
      <p class="text-uppercase font-weight-bold text-18 mb-4">Current Balance</p>
      <div class="text-right">
        <p class="text-36 font-weight-bold mb-1">₦ {{ wallet.lien }}</p>
        <p class="text-uppercase text-14 mb-4">Lien</p>
      </div>
      <b-row class="align-items-end">
        <b-col cols="3">
          <p class="text-14 text-uppercase">Condition</p>
          <b-form-select v-model="selected" :options="options" class="w-100"></b-form-select>
        </b-col>
        <b-col cols="6">
          <p class="text-14 text-uppercase">Amount</p>
          <b-form-input v-model="amount" class="af-input-field"></b-form-input>
        </b-col>
        <div>
          <b-button @click="updateLien" :disabled="processing" variant="outline-primary">
            <span v-if="!processing">Update</span>
            <span v-else>Updating...</span>
          </b-button>
        </div>
      </b-row>
      <div class="text-right mt-5">
        <b-button variant="primary" class="px-4" @click="editLien = false">
          Done
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { editWallet, editLien } from '../../apis/wallet'
import { updateUser } from '../../apis/users'
import { getOneManager } from '../../apis/managers'

export default {
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    },
    wallet: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      editWallet: false,
      editLien: false,
      editProfile: false,
      processing: false,
      name: '',
      email: '',
      managerEmail: '',
      phone: '',
      selected: 'credit',
      amount: '',
      options: [
        { value: 'credit', text: 'CREDIT' },
        { value: 'debit', text: 'DEBIT' }
      ]
    }
  },
  watch: {
    editProfile (val) {
      if (val) {
        this.name = this.user.name
        this.email = this.user.email
        this.phone = this.user.phone
      }
    },
    async user (val) {
      if (val && val.manager) {
        try {
          const { data } = await getOneManager(this.user.manager)
          console.log(data)
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  methods: {
    formatDate (date) {
      return format(new Date(date), 'dd.MMM.yyyy')
    },
    async updateWallet () {
      const payload = {
        type: this.selected,
        amount: this.amount
      }
      this.processing = true
      try {
        await editWallet(payload, this.$router.currentRoute.params.id)
        this.processing = false
        this.editWallet = false
        this.$bvToast.toast('Wallet updated', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.$emit('profile-updated')
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async updateLien () {
      const payload = {
        type: this.selected,
        amount: this.amount
      }
      this.processing = true
      try {
        await editLien(payload, this.$router.currentRoute.params.id)
        this.processing = false
        this.editLien = false
        this.$bvToast.toast('Wallet Lien updated', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.$emit('profile-updated')
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async updateUser () {
      const payload = {
        fullName: this.name,
        phone: this.phone,
        email: this.email,
        managerEmail: this.managerEmail
      }
      this.processing = true
      try {
        await updateUser(payload, this.$router.currentRoute.params.id)
        this.editProfile = false
        this.processing = false
        this.$bvToast.toast('User Profile updated', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        this.$emit('profile-updated')
      } catch (error) {
        this.processing = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
  background: #C4C4C4;
  border-radius: 5px;
  padding: 55px 15px;
  text-align: center;
}
</style>

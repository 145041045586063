import { del, get, post, put } from './http'
import { MANAGERS } from '../utils/routes'

export const getManagers = () => get(MANAGERS)

export const createManager = payload => post(MANAGERS, payload)

export const updateManager = (payload, id) => put(`${MANAGERS}/${id}`, payload)

export const deleteManager = id => del(`${MANAGERS}/${id}`)

export const getOneManager = id => get(`${MANAGERS}/${id}`)

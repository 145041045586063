<template>
  <main class="container-fluid">
    <b-card class="af-card pb-5">
      <b-tabs content-class="mt-3">
        <b-tab title="Profile" title-link-class="px-0 mr-3" active>
          <ProfileInfo :user="user" />
          <Profile :wallet="wallet" :user="user" @profile-updated="getUserInfo" />
        </b-tab>
        <b-tab title="Status" title-link-class="px-0 mr-3">
          <ProfileInfo :user="user" />
          <Status :user="user" />
        </b-tab>
        <b-tab title="Transaction" title-link-class="px-0 mr-3">
          <ProfileInfo :user="user" />
          <Transaction />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import { getOneUser } from '../apis/users'
import Profile from '../components/profile/Profile.vue'
import Status from '../components/profile/Status.vue'
import Transaction from '../components/profile/Transaction.vue'
import ProfileInfo from '../components/profile/ProfileInfo.vue'

export default {
  metaInfo: {
    title: 'Profile',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    Profile,
    Status,
    Transaction,
    ProfileInfo
  },
  data () {
    return {
      user: {},
      wallet: {}
    }
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      try {
        const { data } = await getOneUser(this.$router.currentRoute.params.id)
        this.user = data.data.user
        this.wallet = data.data.wallet
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

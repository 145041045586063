<template>
  <div class="mt-4">
    <h3 class="text-14 font-weight-bold text-uppercase mb-4">User Transaction History</h3>
    <b-row class="justify-content-between">
      <b-col cols="4" class="mt-4">
        <b-form-group class="af-input">
          <b-input-group class="mb-3">
            <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="af-input-append">
                <span class="icon-magnifier"></span>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col class="text-right mt-4">
        <b-form-select v-model="status" :options="options"></b-form-select>
      </b-col>
    </b-row>
    <!-- table -->
    <b-table
      id="af-table"
      :items="fetchTransactions"
      :fields="fields"
      :currentPage="currentPage"
      :per-page="perPage"
      :filter="status"
      hover
      responsive
      borderless
      show-empty
    >

      <template v-slot:head(type)>
        <span class="font-weight-bold text-uppercase">Transaction</span>
      </template>

      <template v-slot:head(amount)>
        <span class="font-weight-bold text-uppercase">Amount</span>
      </template>

      <template v-slot:head(transactionReference)>
        <span class="font-weight-bold text-uppercase">Transaction Reference</span>
      </template>

      <template v-slot:cell(type)="data">
        <span>{{ data.value | capitalize }}</span>
      </template>

      <template v-slot:cell(transactionReference)="data">
        <span>#{{ data.value }}</span>
      </template>

      <template v-slot:head(createdAt)>
        <span class="font-weight-bold text-uppercase">Date</span>
      </template>

      <template v-slot:cell(createdAt)="data">
        <span class="text-secondary">{{ formatDate(data.value) }}</span>
      </template>

      <template v-slot:head(updatedAt)>
        <span class="font-weight-bold text-uppercase">Time</span>
      </template>

      <template v-slot:cell(updatedAt)="data">
        <span class="text-secondary">{{ formatTime(data.value) }}</span>
      </template>

      <template v-slot:cell(status)="data">
        <span v-if="data.value === 'completed'" class="text-success">Completed</span>
        <span v-if="data.value === 'pending'" class="text-warning">Pending</span>
        <span v-if="data.value === 'declined'" class="text-danger">Declined</span>
        <span v-if="data.value === 'failed'" class="text-danger">Failed</span>
      </template>

      <template v-slot:cell(amount)="data">
        <span class="text-success title text-uppercase">₦ {{ data.value }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
      hide-ellipsis
      hide-goto-end-buttons
      aria-controls="af-table"
    >
      <template v-slot:page="{ page, active }">
        <span class="active" v-if="active">{{ page }}</span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { getUserTransactions } from '../../apis/users'

export default {
  data () {
    return {
      currentPage: 1,
      perPage: 25,
      aggregate: 25,
      filter: null,
      selected: null,
      items: [],
      fields: [
        'type',
        'transactionReference',
        'amount',
        'createdAt',
        'updatedAt',
        'status'
      ],
      options: [
        { value: '', text: 'Sort by' },
        { value: 'completed', text: 'Completed' },
        { value: 'pending', text: 'Pending' },
        { value: 'declined', text: 'Declined' },
        { value: 'failed', text: 'Failed' }
      ],
      type: '',
      status: ''
    }
  },
  computed: {
    rows () {
      return this.aggregate
    }
  },
  methods: {
    formatDate (date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    formatTime (time) {
      return format(new Date(time), 'h:mm a')
    },
    async fetchTransactions () {
      let query = `${this.$router.currentRoute.params.id}?page=${this.currentPage}`
      query += this.type ? `&type=${this.type}` : ''
      query += this.status ? `&status=${this.status}` : ''
      try {
        this.isBusy = true
        const { data } = await getUserTransactions(query)
        this.aggregate = data.agreegate
        return data.data
      } catch (error) {
        return []
      } finally {
        this.isBusy = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
<style>
.hidden_header {
  display: none;
}
</style>

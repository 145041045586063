<template>
  <div class="mt-4">
    <h3 class="text-14 font-weight-bold text-uppercase mb-4">User Status</h3>
    <b-row class="no-gutters justify-content-between">
      <p class="mb-1">Suspend User</p>
      <v-switch v-model="isSuspended" :disabled="!isSuspended" theme="custom" color="primary"></v-switch>
    </b-row>
    <form @submit.prevent="">
      <b-form-input v-model="suspensionDuration" type="number" placeholder="Enter duration (in days)" class="input mt-4"></b-form-input>
      <b-form-textarea v-model="suspensionNote" placeholder="Enter suspension note" class="textarea mt-4"></b-form-textarea>
      <b-button @click="suspendUser" :disabled="processingSuspension" variant="primary" class="mt-4">
        Suspend User
      </b-button>
    </form>
    <b-row class="no-gutters justify-content-between mt-5">
      <p>Delete User</p>
      <v-switch v-model="isDeleted" :disabled="!isDeleted" theme="custom" color="primary"></v-switch>
    </b-row>
    <form @submit.prevent="">
      <b-form-textarea v-model="deletionNote" placeholder="Enter deletion note" class="textarea mt-4"></b-form-textarea>
      <b-button @click="deleteUser" :disabled="processingDeletion" variant="primary" class="mt-4">
        Delete User
      </b-button>
    </form>
  </div>
</template>

<script>
import Switches from 'vue-switches'
import { suspendUser, deleteUser, unsuspendUser, undeleteUser } from '../../apis/users'

export default {
  components: {
    'v-switch': Switches
  },
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      suspensionDuration: '',
      suspensionNote: '',
      deletionNote: '',
      isSuspended: false,
      isDeleted: false,
      processingSuspension: false,
      processingDeletion: false
    }
  },
  watch: {
    user (val) {
      if (this.user) {
        this.isSuspended = this.user.suspended
        this.isDeleted = this.user.deleted
      }
    },
    isSuspended (val) {
      if (!val) {
        this.unsuspendUser()
      }
    },
    isDeleted (val) {
      if (!val) {
        this.undeleteUser()
      }
    }
  },
  methods: {
    async suspendUser () {
      if (!this.suspensionNote || !this.suspensionDuration) {
        return this.$bvToast.toast('Please enter a duration and reason for suspension', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
      }
      const payload = {
        note: this.suspensionNote,
        duration: this.suspensionDuration
      }
      this.processingSuspension = true
      try {
        await suspendUser(payload, this.$router.currentRoute.params.id)
        this.suspensionDuration = ''
        this.suspensionNote = ''
        this.processingSuspension = false
        this.$bvToast.toast('User suspended', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error) {
        this.processingSuspension = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async unsuspendUser () {
      try {
        await unsuspendUser(this.$router.currentRoute.params.id)
        this.$bvToast.toast('User unsuspended', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error) {
        this.processingSuspension = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async deleteUser () {
      if (!this.deletionNote) {
        return this.$bvToast.toast('Please enter a reason for deleting user', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
      }
      const payload = {
        note: this.deletionNote
      }
      this.processingDeletion = true
      try {
        await deleteUser(payload, this.$router.currentRoute.params.id)
        this.deletionNote = ''
        this.processingDeletion = false
        this.$bvToast.toast('User deleted', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error) {
        this.processingDeletion = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    },
    async undeleteUser () {
      try {
        await undeleteUser(this.$router.currentRoute.params.id)
        this.$bvToast.toast('User account restored', {
          title: 'Notification',
          variant: 'success',
          solid: true
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error) {
        this.processingSuspension = false
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 25%;
}
.textarea {
  width: 45%;
}
</style>

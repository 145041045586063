<template>
  <b-row class="align-items-end no-gutters">
    <img ref="dp" class="display-picture" width="100" height="100">
    <div class="ml-4">
      <h3 class="text-18 font-weight-bold mb-1">{{ user.name }}</h3>
      <p class="mb-1">{{ user.email }}</p>
      <p :class="{ 'text-uppercase text-14 font-weight-bold mb-0': true, 'text-success': user.verified, 'text-danger': !user.verified }">{{ user.verified ? 'Verified' : 'Not verified'}}</p>
    </div>
  </b-row>
</template>

<script>
import { getFilePath } from '../../apis/users'

export default {
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  watch: {
    user (val) {
      console.log(val.photo)
      if (val && val.photo) {
        this.getFilePath(val.photo)
      }
    }
  },
  methods: {
    async getFilePath (path) {
      console.log(path)
      try {
        const { data } = await getFilePath({ file_path: path })
        this.$refs.dp.src = `data:image/png;base64, ${data.data}`
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.display-picture {
  border-radius: 5px;
  border: 1px solid #CFCFCF;
  overflow: none;
}
</style>
